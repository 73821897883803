export const filters = [
	[
		{
			label: '选择部门',
			type: 'select',
			attr: 'deptId',
			placeholder: '请选择',
			column:8,
			labelWidth: '96px',
		},
		{
			label: '跟进人',
			type: 'select',
			attr: 'userId',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '签单时间',
			type: 'dateRange',
			valueFormat:"YYYY-MM-DD",
			attr: 'date',
			column: 8,
			labelWidth: '100px',
		},
	],

	[
		{
			label: '客户来源',
			type: 'select',
			attr: 'source',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '导入', val: 0 },
				{ label: '系统提供', val: 1 },
			],
		},
		{
			label: '客户名',
			type: 'input',
			attr: 'name',
			placeholder: '请填写客户名',
			column: 8,
			labelWidth: '96px',
		},
		{
			type: 'button',
			column: 8,
		},
	]
]

export const tableColumn = [
	{
		label: '序号',
		width: '120px',
		isCustomize: true,
		tableType: 'no',
	},
	{
		label: '客户名称',
		prop: 'customerName',
        isCustomize: true,
        tableType: "name"
	},
	{
		label: '签单人员',
		prop: 'username',
	},
	{
		label: '签单金额(元)',
		prop: 'amount',
	},
	{
		label: '签单点位(%)',
		prop: 'serviceCharge',
	},
	{
		label: '签单时间',
		prop: 'signDate',
	},
	{
		label: '登记时间',
		prop: 'createTime',
	},
    {
		label: '操作',
		isCustomize: true,
        tableType: 'signOperate',
	},
]
